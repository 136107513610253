module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CoffeeShop","short_name":"CoffeeShop","start_url":"/","background_color":"##2B6CB0","theme_color":"#3182CE","display":"standalone","icon":"src/assets/logo/logoPink-512x512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
